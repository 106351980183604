.email-login {
  display: flex;
  justify-content: left;
  flex-direction: column;
  > .input-item {
    display: block;
    margin-bottom: 1rem;
    p {
      // width: 30%;
      padding-right: 1rem;
      color: #333;
      margin: 0;
      margin-bottom: 0.5rem;
      &.label-pw {
        margin-top: 0.4rem;
      }
    }

    input {
      width: 70%;
      border-radius: 8px;
      border: 1px solid #333;
      font-size: 1rem;
      padding: 0.5rem;
      &.input-pw {
        width: 50%;
      }
    }
  }

  .forgot-password {
    text-align: center;
    text-decoration: underline;
    margin-bottom: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

  > p {
    text-align: left;
    color: #333;
  }

  button {
    padding-bottom: 12px;
    margin-top: 4px;
    display: block;
    width: 5rem;
    margin-bottom: 0.5rem;

    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
    padding: 8px 17px;
    font-weight: bold;
    color: #fff;
    text-shadow: none;
    background-color: #2ecc71;
    border: none;
    display: inline-block;
    opacity: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .warning {
    color: red;
  }
}
