.personal-page {
  h1 > span {
    color: red;
    font-size: 50px;
  }
  h2 {
    margin-bottom: 1rem;
  }
  h3 {
    font-weight: normal;
    font-size: 1.3rem;
    margin-top: 0;
    margin-bottom: 3rem;
    color: bisque;
  }
  h5 {
    color: #efefef;
    font-size: 1rem;
    // font-weight: normal;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .image-list {
    // min-width: 512px;
    .image-date {
      color: #000;
      font-style: italic;
      border-top: 1px solid rgb(3, 95, 77);
      margin-top: 3rem;
      padding-top: 2rem;
    }
    .image-detail {
      display: flex;
      position: relative;
      align-items: stretch;
      margin: 1rem 0;
    }
    .image-detail {
      div > span {
        font-size: 0.83em;
        font-style: italic;
        font-weight: bold;
      }
    }
    .date {
      vertical-align: top;
      margin-top: 0.7rem;
    }
    p {
      font-style: italic;
    }
    .tag-wrapper {
      flex-basis: 99%;
      color: bisque;
    }
    .editabletagbox {
      border: none;
      color: bisque;

      background: rgba(255, 255, 255, 0.2);
      height: auto;
      padding: 10px 10px;
      box-shadow: none;
      border-radius: 0;
      font-size: 15px;
      outline: 0;
      height: auto;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #2a9d8f;

      &::placeholder {
        color: #fff;
        opacity: 0.3;
      }
      &:focus {
        border: 1px solid rgb(188, 187, 187);
      }
    }
    .delete-btn {
      flex-basis: 1%;
      display: inline-block;
      color: #fff;
      padding: 8px 10px;
      font-size: 1rem;
      background: #c10d26;

      i {
        font-size: 1rem;
        padding-bottom: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.flex-wrapper {
  display: flex;
  position: relative;
  justify-content: space-around;
  margin-right: 20px;
  @media screen and (max-width: 500px) {
    margin-right: 0;
  }
  .tag-container {
    position: absolute;
    right: 0;
    @media screen and (max-width: 500px) {
      border: 1px solid #ccc;
    }
  }
}

@media screen and (max-width: 500px) {
  .tag-container {
    display: none;
    position: relative;
  }
  .tag-container.mobile-responsive {
    display: block;
    position: absolute;
  }
}

.tag-side {
  background: #2a9d8f;
  padding: 0.5rem 3rem 4rem 3rem;
  margin: auto;
  color: #000;
  max-width: 200px;
  margin-top: 1rem;
  border-radius: 10px;
  align-items: center;
  //border: 1px solid #264653;
  @media screen and (max-width: 260px) {
    border: 0;
    padding: 1rem;
    padding-bottom: 2rem;
    margin-top: 0;
    border-radius: 0;
    // min-height: 1000px;
  }
}

.tag-content {
  position: relative;
  list-style-type: none;
  display: flex;
  margin-top: 15px;

  .tag-icon {
    padding: 0.5em 25px 0.5em 0.5em;
    background-color: bisque;
    font-size: 15px;
    border-radius: 8px;
    display: block;
    margin-right: 10px;
  }
  .tag-name {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    margin-left: 0.5em;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
  }

  .tag-counter {
    background-color: #3d3a3b;
    border: 1px solid #3d3a3b;
    border-radius: 20px;
    color: #e1e5eb;
    display: inline-block;
    font-size: 11px;
    line-height: 24px;
    min-width: 10px;
    padding: 0 6px;
    margin-top: 0.3em;
    text-align: center;
  }
}
