@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');
@font-face {
  font-family: storybookFont;
  src: url(../../fonts/Storyboo.TTF);
}
//theme
$yellow: #d4bbff;
$black: #292929;
$bg: #191919;
$lines: #757474;
$robot: #ccc;

html {
  overflow-y: scroll;
}
body {
  background: #2a9d8f;
  background: linear-gradient(to right, #238277, #2a9d8f);
  transition: background-color 1000ms linear;
  font-family: 'Open Sans', sans-serif;
}
body {
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: none;
  margin: 0 auto;
  background-color: #238277;
  margin-bottom: 3rem;
  @media screen and (max-width: 500px) {
    margin-bottom: 1rem;
  }
  a {
    color: #000;
  }
  h1 {
    font-family: storybookFont;
    font-style: italic;
    font-size: 3.2rem;
    color: #fff;
    font-weight: normal;
    margin: 2rem;
    display: inline-block;
    margin-top: 1.5rem;
    @media screen and (max-width: 500px) {
      margin: 0.8rem;
      margin-left: 1rem;
      font-size: 2.8rem;
    }
    span {
      display: none;
      font-family: 'Courier New', Courier, monospace;
      font-style: normal;
      font-weight: normal;
      font-size: 2.5rem;
      margin-left: 0rem;
      @media screen and (max-width: 500px) {
        font-size: 2rem;
        margin-left: 0.1rem;
      }
    }
  }
  .link-mypage {
    margin-right: 0.5rem;
    color: #fff;
    text-decoration: none;
  }
  .account-container {
    margin: 0 2rem;
    @media screen and (max-width: 500px) {
      margin-left: 1.3rem;
      font-size: 2.7rem;
      display: none;
    }

    display: flex;
    > div {
      flex: 1;
      padding: 14px 26px;
      margin-right: 4px;
      color: white;
      font-weight: 700;

      &:hover {
        cursor: pointer;
      }
    }

    .login-btn {
      background-color: #00cf35;
      margin-right: 8px;
    }

    .sign-up-btn {
      background-color: #00b8ff;
    }

    .log-out-btn {
      background-color: #fff;
      color: #c10d26;
      margin-left: 1rem;
    }
  }
}
.App-header {
  background: #2a9d8f;
  padding: 0.5rem 3rem 4rem 3rem;
  margin: 0 auto;
  color: #000;
  max-width: 512px;
  border-radius: 10px;
  @media screen and (min-width: 900px) {
    min-width: 512px;
  }
  // border: 1px solid #264653;
  @media screen and (max-width: 500px) {
    border: 0;
    padding: 1rem;
    padding-bottom: 2rem;
    margin-top: 0;
    border-radius: 0;
    // min-height: 1000px;
  }
  font-family: 'Open Sans', sans-serif;
  h2 {
    font-size: 1.8rem;
    color: #e9c46a;
    a {
      color: #e9c46a;
    }
    @media screen and (max-width: 500px) {
      margin-top: 0.5rem;
      font-size: 1.7rem;
    }
  }
  .imageForm {
    text-align: left;
  }
  form input[type='text'] {
    text-transform: lowercase;
    border-radius: 8px;
  }
  input.descriptionBox {
    border: none;
    color: bisque;

    background: rgba(255, 255, 255, 0.2);
    height: auto;
    padding: 15px 15px;
    box-shadow: none;
    border-radius: 0;
    font-size: 22px;
    outline: 0;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #2a9d8f;

    &::placeholder {
      color: #fff;
      opacity: 0.3;
    }
    &:focus {
      border: 1px solid rgb(188, 187, 187);
    }
  }
  input.tag-box {
    border: none;
    color: bisque;

    background: rgba(255, 255, 255, 0.2);
    padding: 10px 10px;
    height: auto;
    box-shadow: none;
    border-radius: 0;
    font-size: 1rem;
    outline: 0;
    width: 92%;
    box-sizing: border-box;
    @media screen and (max-width: 500px) {
      width: 100%;
    }
    &::placeholder {
      color: #fff;
      opacity: 0.3;
    }
    &:focus {
      border: 1px solid rgb(188, 187, 187);
    }
  }
  button {
    font-size: 1.2rem;
    margin-top: 1.2rem;
    margin-bottom: 2.5rem;
    padding: 10px 20px;
    font-weight: normal;
    color: #fff;
    text-shadow: none;
    background-color: #2ecc71;
    border: none;
    display: inline-block;
    opacity: 1;

    &.btn-save {
      position: absolute;
      top: 8px;
      right: 0;
      font-size: 0.94rem;
      margin: 0;
      font-weight: normal;
      @media screen and (max-width: 500px) {
        position: relative;
        display: block;
      }
      &.save-icon {
        position: relative;
        top: 0px;
        padding: 10px 10px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-left: 2px;
        height: auto;
        @media screen and (max-width: 500px) {
          position: relative;
        }
        i {
          font-size: 1rem;
          padding-right: 0;
        }
      }
    }
  }
  .random-wrapper {
    text-align: left;
    color: #fff;
    margin-top: 1.3rem;
    margin-bottom: 0.5rem;
    a {
      font-size: 0.8rem;
      color: #fff;
    }
  }
  .more-styles {
    border: 1px solid #ccc;
    padding: 0.4rem 0.6rem;
    border-radius: 0.3rem;
    background-color: bisque;
    color: #000 !important;
    margin-right: 0.7rem;
    text-decoration: none;
    i {
      margin-right: 0.3rem;
    }
  }
  .styles-wrapper {
    margin-top: 1.5rem;
    margin-bottom: 0;
    color: bisque;
    span {
      color: #ccc;
      font-size: 0.75rem;
      vertical-align: super;
    }
  }
  .clearBtn {
    margin-left: 0.7rem;
    background-color: #006373;
  }
  button:hover {
    cursor: pointer;
  }

  .checkmarkWrapper {
    margin-top: 0.7rem;
    @media screen and (max-width: 500px) {
      width: 100%;
      height: 65px;
      margin-bottom: 0px;
      overflow-x: scroll;
      white-space: nowrap;
      &::-webkit-scrollbar {
        width: 0px;
        height: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #2da495;
        border: 1px solid #ccc;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ccc;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ccc;
      }
    }
  }

  .checkboxCustom {
    appearance: none;
    height: 40px;
    width: 110px;
    background-color: bisque;
    background-size: 360% 100%;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ccc;
  }

  .checkboxCustom:after {
    content: attr(data-name);
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    color: #fff;
    z-index: 99;
    //transition: all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  .checkboxCustom:hover:after {
    color: #fff;
  }
  .checkboxCustom:before {
    content: '';
    position: absolute;
    top: 0%;
    left: 0px;
    height: 200%;
    width: 200%;
    //background: #fff;
    background: #2a9d8f;
    z-index: 0;
    // transition: all 0.2s linear;
    transform: scale(0.6) translate(-50%, -50%);
  }

  .checkboxCustom:checked:before {
    top: -100%;
  }
  .checkboxCustom:checked:after {
    color: #666;
  }
  .checkboxCustom:focus-visible {
    outline: none;
    box-shadow: 0px 0px 0px 5px #2a9d8f;
  }

  .generateWrapper {
    display: inline-block;
    margin-left: 1.5rem;
    color: #fff;
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
  .errorMessage {
    margin-top: 0;
    color: rgb(107, 7, 7);
  }
  .generatedDuration {
    color: #014853;
    @media screen and (max-width: 500px) {
      font-size: 0.85rem;
      margin-top: 2rem;
    }
    a {
      color: #e9c46a;
    }
  }
  .generatedSeed {
    color: #fff;
    margin-top: 0.5rem;
    line-height: 2rem;
    @media screen and (max-width: 500px) {
      font-size: 0.85rem;
    }
    span {
      background-color: #333;
      padding: 0.3rem 0.4rem;
      color: #e9c46a;
      // border-radius: 5px;
    }
  }
  .fadeIn {
    opacity: 1;
  }
  .App-link {
    color: #61dafb;
  }
}
.imgContainer {
  @media screen and (max-width: 500px) {
    border: 1px;
  }
  // height: 512px;
  animation-duration: 1.3s;
  // transition: height 1.3s;
  img {
    border: 1px solid #ccc;
    opacity: 1;
    // animation: fadeIn 1s;
  }
}
.placeholderImg {
  opacity: 1;
  animation: fadeIn 1s;

  @media screen and (max-width: 500px) {
    // display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hide {
  display: none;
}
.animateContainer {
  // height: 512px;
  position: relative;

  @media screen and (max-width: 500px) {
    //height: 100%;
  }
}

img {
  width: 100%;
  height: 512px;
  @media screen and (max-width: 500px) {
    height: auto;
  }
  &.loadingImg {
    //height: 512px;
    opacity: 0.2;
    filter: grayscale(100%);
  }
}
.thumbsContainer {
  position: relative;
  .thumbnail {
    // border: 1px solid #ccc;
    border-radius: 6px;
    display: inline-block;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    width: 80px;
    height: 80px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}

.optionCheckmarks {
  display: inline-block;
  text-align: left;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
  input:checked + label {
    color: bisque;
  }
  label {
    margin-left: 0.15rem;
    color: #efefef;
  }
}

.footerText {
  margin: 0 auto;
  margin-top: 2rem;
  padding-bottom: 2rem;
  color: #000;
  max-width: 512px;
  color: #55b1a5;
  font-size: 0.9rem;
  @media screen and (max-width: 500px) {
    padding-left: 1.5rem;
  }
  a {
    color: #fff;
    &:hover {
      color: #ccc;
    }
  }
}

.blink {
  animation: blinker 1.5s step-start infinite;
  color: #ccc;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.progressContainer {
  position: absolute;
  margin: 0;
  width: 100%;
  z-index: 1;
  opacity: 1;
  height: 100%;
}
.progressContainer::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0;
  height: 1%;
  background-color: bisque;
  transform-origin: left;
  transition: width 12s ease;
  z-index: -1;
}
.progressContainer.animateProgress::after {
  width: 0;
  animation: transit 10s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}
@keyframes transit {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.loadingCounter {
  position: absolute;
  left: 1rem;
  top: 1.5rem;
  padding-right: 1.5rem;
  font-size: 0.9rem;
  z-index: 1;
  .blink {
    animation: blinker 1.5s step-start infinite;
    color: #fff;
    font-size: 0.9rem;
    margin-top: 1rem;
    display: block;
  }

  @keyframes blinker {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
  p {
    display: block;
    width: 0px;
    margin: 0;
    font-size: 0.9rem;
    color: #ccc;
    overflow: hidden;
    font-family: monospace;
    white-space: nowrap;
    animation-name: writeText;
    animation-duration: 100ms;
    animation-fill-mode: forwards;
    margin-bottom: 0.25rem;
  }
  .delay1 {
    animation-delay: 500ms;
  }
  .delay2 {
    animation-delay: 1000ms;
  }
  .delay4 {
    animation-delay: 1200ms;
    margin-bottom: 0.5rem;
  }
  .delay5 {
    animation-delay: 1500ms;
    animation-duration: 1ms;
    white-space: break-spaces;
  }
  .delay6 {
    animation-delay: 1800ms;
  }
  .delay7 {
    animation-delay: 2200ms;
    color: bisque;
  }
  /* Standard syntax */
  @keyframes writeText {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #loader {
    width: 100%;
    height: 2px;
    background-color: black;
  }

  #loaded {
    height: 2px;
    width: 0px;
    background-color: #20c20e;
    animation-name: load;
    animation-duration: 3000ms;
    animation-fill-mode: forwards;
  }

  @keyframes load {
    0% {
      width: 0;
    }
    50% {
      width: 70%;
    }
    70% {
      width: 100%;
    }
    80% {
      opacity: 1;
    }
    100% {
      width: 100%;
      opacity: 1;
    }
  }
}

.mobile-wrapper {
  display: none;
  @media screen and (max-width: 500px) {
    display: block;
  }
}

#menuToggle {
  display: block;
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;
  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
  * Just a quick hamburger
  */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/*
  * Transform all the slices of hamburger
  * into a crossmark.
  */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
  * But let's hide the middle one.
  */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
  * Ohyeah and the last one should go the other direction
  */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
  * Make this absolute positioned
  * at the top left of the screen
  */
#menu {
  position: absolute;
  width: 140px;
  // margin: -340px 0 0 0px;
  padding: 20px;
  right: -25px;
  top: -230px;
  padding-top: 210px;
  padding-bottom: 50px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(0, -100%);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 1rem;
}

/*
  * And let's slide it in from the left
  */
#menuToggle input:checked ~ ul {
  // transform: none;
}

#menuToggle ul.open-menu {
  transform: none;
}

button i,
.log-out-btn i,
.login-btn i {
  padding-right: 6px;
}
.info-msg,
.success-msg,
.warning-msg,
.error-msg {
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  max-width: 588px;
  margin: 0 auto;
  margin-bottom: 1rem;
  i {
    padding-right: 6px;
  }
  a {
    color: #333;
  }
}
.info-msg {
  color: #059;
  background-color: #bef;
}
.success-msg {
  color: #270;
  background-color: #dff2bf;
}
.warning-msg {
  color: #9f6000;
  background-color: #feefb3;
}
.error-msg {
  color: #d8000c;
  background-color: #ffbaba;
}

.mobileNavAbove {
  display: none;
  color: #fff;
  @media screen and (max-width: 500px) {
    display: block;
    max-width: 500px;
    margin: 0 1rem;
    padding-bottom: 1rem;
    .divide {
      margin: 0 10px;
    }
    a {
      color: #fff;
    }
    p {
      display: inline;
    }
  }
}
