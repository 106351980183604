.login {
  display: flex;
  justify-content: left;
  flex-direction: column;
  > .input-item {
    display: flex;
    margin-bottom: 1rem;
    p {
      width: 30%;
      font-weight: 700;
      color: #333;
    }

    input {
      flex: 1;
      border-radius: 8px;
      border: 1px solid #333;
      font-size: 1rem;
    }
  }

  > p {
    margin: 0 auto;
    margin-bottom: 1rem;
    color: #333;
    font-weight: bold;
  }

  .login-buttons-wrapper {
    margin: 0 auto;
    overflow: hidden;
    button {
      padding-left: 10px;
      font-size: 0.9rem;
      padding-top: 12px;
      padding-bottom: 12px;
      margin-top: 4px;
      display: block;
      width: 18rem;
      margin-bottom: 0.5rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.google-container {
  font-family: arial !important;
}
.gg-mail,
.gg-mail::after {
  display: block;
  box-sizing: border-box;
  height: 14px;
  border: 2px solid #999;
}
.gg-mail {
  overflow: hidden;
  float: left;
  margin-right: 18px;
  transform: scale(var(--ggs, 1));
  position: relative;
  width: 18px;
  border-radius: 2px;
}
.gg-mail::after {
  content: '';
  position: absolute;
  border-radius: 3px;
  width: 14px;
  transform: rotate(-45deg);
  bottom: 3px;
  left: 0;
}
