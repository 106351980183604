.google-login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  > .input-item {
    display: flex;
    margin-bottom: 1rem;
    p {
      width: 30%;
      font-weight: 700;
      color: #eee;
    }

    input {
      flex: 1;
      border-radius: 8px;
      border: 1px solid #eee;
      font-size: 2rem;
    }
  }

  > p {
    text-align: center;
    color: #eee;
  }

  button {
    background-color: #00cf35;
    padding: 14px 26px;
    margin-right: 4px;
    color: white;
    font-weight: 700;
    font-size: 20px;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
    }
  }
}
